<template>
  <v-tabs>
    <v-tab key="balances">Balances</v-tab>
    <v-tab v-if="isFutures(accountType)" key="positions"
      >Positions
      <div v-if="positions !== undefined && positions.length > 0">({{ positions.length }})</div></v-tab
    >
    <v-tab key="open_orders"
      >Open Orders
      <div v-if="open_orders != undefined && open_orders.length > 0">({{ open_orders.length }})</div></v-tab
    >

    <v-tab-item key="balances">
      <balance-table :balances="balances">
      </balance-table>
    </v-tab-item>

    <v-tab-item v-if="isFutures(accountType)" key="positions">
      <position-table :positions="positions" :gatewayName="gatewayName">
      </position-table>
    </v-tab-item>

    <v-tab-item key="open_orders">
      <order-table :orders="open_orders" :gatewayName="gatewayName" :isAlive="true">
      </order-table>
    </v-tab-item>
  </v-tabs>
</template>

<script>

import { checkMixin, } from '@/mixins';

import BalanceTable from "@/views/dashboard/component/BalanceTable";

import OrderTable from "@/views/dashboard/component/OrderTable";

import PositionTable from "@/views/dashboard/component/PositionTable";

export default {
  name: "SfAccountPanel",

  mixins: [checkMixin],

  components: {
    BalanceTable,
    OrderTable,
    PositionTable,
  },

  props: {
    gatewayName: String,
    accountType: String,
    open_orders: Array,
    positions: Array,
    balances: Array,
  },

  watch: {
  },

  data() {
    return {
    };
  },
};
</script>
