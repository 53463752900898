<template>
  <v-data-table dense width="100%" :headers="balance_header" :items="balances" class="elevation-1" sort-by="total"
    :sort-desc="true">
  </v-data-table>
</template>

<script>
export default {
  name: "BalanceTable",

  props: {
    balances: Array,
  },

  data() {
    return {
      balance_header: [
        { text: "Symbol", value: "symbol" },
        { text: "Total", value: "total" },
        { text: "Free", value: "free" },
        { text: "Locked", value: "locked" },
      ],
    };
  },
};
</script>
