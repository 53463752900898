<template>
  <v-container id="account" fluid tag="section">

    <v-divider />

    <v-row v-for="(acc, i) in accLst" :key="i">
      <v-col cols="12">
        <v-row>
          <v-col cols="12">
            {{ acc.name }} | Type: {{ acc.type }}
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <sf-account-panel :accountType="acc.type" :open_orders="openOrderMap[acc.name + '_' + acc.type]" :balances="acc.balances"
              :positions="acc.positions" :gatewayName="acc.name"></sf-account-panel>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

  </v-container>
</template>

<script>
import {
  get_account_info,
  get_account_list,
  get_open_orders,
} from "@/api";

import SfAccountPanel from "@/views/dashboard/component/SfAccountPanel";

import { routerMixins, } from "@/mixins";

export default {
  mixins: [routerMixins,],

  components: {
    SfAccountPanel,
  },

  destroyed() {
    clearInterval(this.timer);
  },

  mounted: function () {
    get_account_info()
      .then(({ acc_name, baseInfos }) => {
        if (acc_name != undefined) {
          this.acc_name = acc_name;
        }
        if (baseInfos != undefined) {
          this.baseInfos = baseInfos;
        }
      })
      .catch((reason) => {
        console.error(reason)
      });

    this.timer = setInterval(() => {
      this.initData().then(() => { }).catch(() => {
        this.logout();
      });
    }, 5000);

    this.initData();
  },

  watch: {
  },

  data() {
    return {
      acc_name: "",
      baseInfos: {},
      timer: -1,
      accountUpdate: {},

      positions: [],
      futures_open_orders: [],

      accLst: [],
      openOrderMap: {},
      spot_balances: [],
      futures_balances: [],
      futures_positions: [],
    };
  },

  methods: {
    initData: async function () {
      const accounts = await get_account_list();
      this.accLst = accounts.accLst;

      const open_orders = await get_open_orders();
      this.openOrderMap = open_orders.openOrderMap;

      this.$forceUpdate()

      // this.spot_open_orders = open_orders.spotOrderList;
      // this.futures_open_orders = open_orders.futuresOrderList;
    },
  },
};
</script>
